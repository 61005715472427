import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import ContactUs from "@components/common/ContactUs"
import * as s from "./Form.module.scss"

const Form: React.FC<{ title: string; pageHandle: string }> = () => {
  return (
    <Container className={s.container}>
      <Row>
        <Col xs={12}>
          <h2 className={s.title}>Contact Us</h2>
        </Col>
        <Col xs={12}>
          <ContactUs />
        </Col>
      </Row>
    </Container>
  )
}

export default Form
