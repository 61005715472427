import { Col, Container, Row } from "react-bootstrap"
import { Employee, employee_types } from "@utils/globalTypes"
import React, { FC } from "react"

import EmployeeCard from "../EmployeeCard"
import EmployeeData from "../EmployeeData"
import { MOBILE_WIDTH } from "@utils/util"
import * as s from "./EmployeesContainer.module.scss"
import useResizeWidth from "@hooks/useResizeWidth"

type Props = {
  employees: Employee[]
  type?: employee_types
  title: string
  id?: string
  card?: boolean
  onClick(employee: Employee): void
  tabIndex: number
}

const WRAP_CARD_CONSTANT = 4
const EmployeesContainer: FC<Props> = ({
  id,
  employees,
  type,
  title,
  card = true,
  onClick,
  tabIndex,
}) => {
  const { width } = useResizeWidth()
  const employeesFiltered = type
    ? employees.filter((employee) => employee.category === type)
    : employees

  const boardOfDirectors =
    type === employee_types.boardMember ? { paddingTop: 0 } : null
  const customStyle = (index: number) =>
    width >= MOBILE_WIDTH && index % WRAP_CARD_CONSTANT === 0
      ? { padding: "0 1rem 0 0" }
      : null
  return (
    <Container
      id={id}
      tabIndex={tabIndex}
      style={boardOfDirectors}
      className={s.container}
    >
      <Row className={s.titleContainer}>
        <h2>{title}</h2>
      </Row>
      <Row>
        {employeesFiltered.map((employee, index) => (
          <Col
            style={customStyle(index)}
            className="mb-4"
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={index}
          >
            {card ? (
              <EmployeeCard
                onClick={onClick}
                isMedical={type === employee_types.medical}
                employee={employee}
              />
            ) : (
              <EmployeeData employee={employee} onClick={onClick} />
            )}
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default EmployeesContainer
