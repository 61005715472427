import React from "react"
import { Container, Row } from "react-bootstrap"
import { BaseBlockContent } from "@utils/richText"
import { SanityAbout } from "@utils/globalTypes"
import * as s from "./AboutSection.module.scss"

type Props = {
  sanityAbout: SanityAbout
}

const AboutSection: React.FC<Props> = ({ sanityAbout }) => {
  return (
    <Container className={s.container}>
      {sanityAbout?._rawHtmlBlock && (
        <Row>
          <BaseBlockContent blocks={sanityAbout?._rawHtmlBlock} />
        </Row>
      )}
    </Container>
  )
}

export default AboutSection
