import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Feature } from "@utils/globalTypes"
import FeatureElement from "@components/common/FeatureElement"
import * as s from "./FeatureBenefits.module.scss"

type Props = {
  featureBenefits: Feature[]
}

const FeatureBenefits: React.FC<Props> = ({ featureBenefits }) => {
  if (!featureBenefits || !featureBenefits.length) {
    return <></>
  }

  return (
    <div className={s.sectionContainer}>
      <Container className={s.container}>
        <Row>
          <Col xs={12}>
            <h2 className={s.title}>Feature / Benefits</h2>
          </Col>
        </Row>
        <Row className={s.row}>
          {featureBenefits.map((featureBenefit, index) => (
            <Col xs={6} md={3} key={index}>
              <FeatureElement feature={featureBenefit} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default FeatureBenefits
