import {
  SanityAbout,
  SanityFaqs,
  SanityFeatureBenefit,
  SanityLandingPage,
  SanityButtonLink,
  SanityQuote,
  SanityResource,
  sanity_content,
  SanityPage,
  Employee,
  EmployeeSection,
} from "@utils/globalTypes"

import AboutSection from "./AboutSection/AboutSection"
import FAQs from "./FAQs/FAQs"
import FeatureBenefits from "./FeatureBenefits/FeatureBenefits"
import Form from "./Form/Form"
import GuidesAndResources from "./GuidesAndResources/GuidesAndResources"
import QuoteContainer from "./QuoteContainer/QuoteContainer"
import React, { useState } from "react"
import SEO from "@components/common/SEO"
import TitleContainer from "@components/common/TitleContainer"
import { graphql } from "gatsby"
import { Container } from "react-bootstrap"
import * as s from "./LandingPage.module.scss"
import CarouselContainer from "@components/homePage/CarouselContainer"
import EmployeeDetail from "@components/employees/EmployeeDetail"
import EmployeesContainer from "@components/employees/EmployeesContainer"

type Props = {
  data: {
    sanityLandingPage: SanityLandingPage
  }
  location: Location
}

const LandingPage: React.FC<Props> = ({
  data: { sanityLandingPage },
  location,
}) => {
  const metadata = {
    location: location.href,
  }
  const {
    title,
    subtitle,
    image,
    cta,
    content,
    backgroundImage,
    handle: { current },
  } = sanityLandingPage
  const [employee, setEmployee] = useState<Employee>(null)
  const [show, setShow] = useState<boolean>(false)
  const showEmployeeInfo = (employee: Employee) => {
    setEmployee(employee)
    //setEmployeeParam(employee.slug.current)
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
    setEmployee(null)
    //setEmployeeParam(undefined)
  }

  const showResourcesTitle =
    content.filter((item) => item._type === sanity_content.resources).length ===
    1

  return (
    <>
      <SEO {...metadata} />
      <TitleContainer
        title={title}
        subtitle={subtitle}
        image={image}
        backgroundImage={backgroundImage}
        cta={cta}
      />
      {content.map((contentSection, index) => {
        switch (contentSection._type) {
          case sanity_content.about:
            const aboutSection = contentSection as SanityAbout
            return <AboutSection key={index} sanityAbout={aboutSection} />
          case sanity_content.faqs:
            const faqsSection = contentSection as SanityFaqs
            return <FAQs key={index} faqs={faqsSection.faq} title={title} />
          case sanity_content.featuresBenefits:
            const featureBenefits = contentSection as SanityFeatureBenefit
            return (
              <FeatureBenefits
                key={index}
                featureBenefits={featureBenefits.featureBenefit}
              />
            )
          case sanity_content.quote:
            const quote = contentSection as SanityQuote
            return <QuoteContainer key={index} quote={quote} />
          case sanity_content.resources:
            const resources = contentSection as SanityResource
            return (
              <GuidesAndResources
                key={index}
                resources={resources.resources}
                title={title}
                showTitle={showResourcesTitle}
              />
            )
          case sanity_content.linkButton:
            const linkButton = contentSection as SanityButtonLink
            return (
              <Container key={index} className={s.container}>
                <a href={linkButton.link}> {linkButton.label}</a>
              </Container>
            )
          case sanity_content.partners:
            return (
              <CarouselContainer
                key={index}
                sanityPage={sanityLandingPage as SanityPage}
              />
            )
          case sanity_content.employeeSection:
            const section = contentSection as EmployeeSection
            return (
              <EmployeesContainer
                key={index}
                title={section?.title}
                employees={section?.employees}
                onClick={(employee) => showEmployeeInfo(employee)}
              />
            )
          default:
            return <div key={index}></div>
        }
      })}
      {!!employee && (
        <EmployeeDetail employee={employee} show={show} onHide={handleClose} />
      )}
      <Form title={title} pageHandle={current} />
    </>
  )
}

export default LandingPage

export const query = graphql`
  query sanityLandingPage($handle: String!) {
    sanityLandingPage(handle: { current: { eq: $handle } }) {
      title
      subtitle
      handle {
        current
      }
      cta {
        label
        link
      }
      image {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      backgroundImage {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      content {
        ... on SanityAbout {
          _rawHtmlBlock(resolveReferences: { maxDepth: 10 })
          _type
        }
        ... on SanityFaqs {
          faq {
            question
            _rawAnswer(resolveReferences: { maxDepth: 10 })
          }
          _type
        }
        ... on SanityFeaturesBenefits {
          featureBenefit {
            title
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          _type
        }
        ... on SanityLinkButton {
          label
          link
          _type
        }
        ... on SanityQuote {
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          _type
          _rawText(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityResources {
          resources {
            ... on SanityLandingPage {
              _type
              title
              handle {
                current
              }
            }
            ... on SanityResourcePage {
              _type
              title
              slug {
                current
              }
              category {
                name
                slug {
                  current
                }
              }
            }
          }
          _type
        }
        ... on SanityPartners {
          images {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          _type
        }
        ... on SanityEmployeesSection {
          id
          title
          _type
          employees {
            category
            name
            order
            _rawBio(resolveReferences: { maxDepth: 10 })
            position
            slug {
              current
            }
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`
