import React from "react"
import { SanityQuote } from "@utils/globalTypes"
import Quote from "@components/common/Quote"
import * as s from "./QuoteContainer.module.scss"
import { Container } from "react-bootstrap"

type Props = {
  quote: SanityQuote
}

const QuoteContainer: React.FC<Props> = ({ quote }) => {
  return (
    <Container className={s.sectionContainer}>
      <Quote quote={quote} />
    </Container>
  )
}

export default QuoteContainer
