import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import * as s from "./FAQs.module.scss"
import { FAQ as IFAQ } from "@utils/globalTypes"
import FAQ from "@components/common/FAQ"

type Props = {
  title: string
  faqs: IFAQ[]
}

const FAQs: React.FC<Props> = ({ faqs, title }) => {
  return (
    <Container className={s.container}>
      <Row>
        <Col xs={12}>
          <h2 className={s.title}>{title} FAQs</h2>
        </Col>
        {faqs.map((faq, index) => (
          <Col xs={12} md={6} key={index}>
            <FAQ {...faq} />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default FAQs
