import { Col, Row } from "react-bootstrap"
import React, { FC } from "react"

import { Employee } from "@utils/globalTypes"
import * as s from "./EmployeeData.module.scss"

type Props = {
  employee: Employee
  onClick(employee: Employee): void
}

const EmployeeData: FC<Props> = ({ employee, onClick }) => {
  const { name, position } = employee
  return (
    <Row className={s.container} onClick={() => onClick(employee)}>
      <Col xs={12}>
        <span className={s.name}>{name}</span>
      </Col>
      <Col xs={12} className={s.insideContainer}>
        <span className={s.position}>{position}</span>
      </Col>
    </Row>
  )
}

export default EmployeeData
