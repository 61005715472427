import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Resource } from "@utils/globalTypes"
import * as s from "./GuidesAndResources.module.scss"
import ResourceElement from "@components/common/ResourceElement"

type Props = {
  title: string
  resources: Resource[]
  showTitle?: boolean
}

const GuidesAndResources: React.FC<Props> = ({
  title,
  resources,
  showTitle = true,
}) => {
  return (
    <div className={s.sectionContainer}>
      <Container className={s.container}>
        <Row className="justify-content-center">
          {showTitle && (
            <Col xs={12}>
              <h2 className={s.title}>{title}</h2>
            </Col>
          )}
          {resources.map((resource, index) => (
            <Col xs={6} md={3} className="my-3" key={index}>
              <ResourceElement className={s.resource} resource={resource} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default GuidesAndResources
