import React, { FC } from "react"

import { Card } from "react-bootstrap"
import { Employee } from "@utils/globalTypes"
import { GatsbyImage } from "gatsby-plugin-image"
import * as s from "./EmployeeCard.module.scss"

type Props = {
  employee: Employee
  isMedical: boolean
  onClick(employee: Employee): void
}

const EmployeeCard: FC<Props> = ({ employee, isMedical, onClick }) => {
  const { position, image, title, name } = employee
  const fullName = isMedical ? `${name}, ${title}` : name
  const backgroundColor = isMedical ? "#FFF" : "#f6f8fb"
  return (
    <Card
      style={{ backgroundColor }}
      className={s.card}
      onClick={() => onClick(employee)}
    >
      <Card.Img className={s.imageContainer} as="div" variant="top">
        <GatsbyImage
          style={{ width: 180 }}
          alt={title}
          image={image?.asset?.gatsbyImageData}
        />
      </Card.Img>
      <Card.Body className={s.bodyContainer}>
        <div className={s.title}>{fullName}</div>
        <div className={s.description}>{position}</div>
      </Card.Body>
    </Card>
  )
}

export default EmployeeCard
